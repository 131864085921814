import React from "react";
import { Grid, Typography } from "@mui/material";
import "./App.css";

function App() {
	return (
		<Grid
			container
			direction="row"
			alignItems="center"
			sx={{ width: "100vw", height: "100vh", backgroundColor: "#fefefe" }}>
			<Grid
				item
				xs={6} // Occupies half the width on all screen sizes
				sx={{
					backgroundImage:
						'url("https://www.nos.pt/content/dam/nos/porque-a-nos/en/wi-fi-speed.jpg")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					height: "100vh", // Full height of the viewport
				}}>
				aaAAA
			</Grid>
			<Grid item xs={6} sx={{ height: "100vh" }}>
				<Grid
					container
					direction="column"
					alignContent={"center"}
					justifyContent={"center"}
					height="100%">
					<Grid item>
						<Typography variant="h1">
							<b>NOS</b> Vila Verde
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h4">Website em Construção</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6">geral@nosvilaverde.com</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default App;
